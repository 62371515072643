import { Suspense, lazy } from 'react';
import { Outlet } from 'react-router-dom';

// auth
import { AuthenticatedTemplate } from '@azure/msal-react';

// layouts
import DashboardLayout from '../../layouts/dashboard';

// components
import { LoadingScreen } from '../../components/loading-screen';
import { MaintenanceModeAlert } from '../../components/maintenance';
import { rating } from '../../theme/overrides/components/rating';
// ----------------------------------------------------------------------
const DashboardPage = lazy(() => import('../../pages/dashboard/DashboardPage'));
const UsersPage = lazy(() => import('../../pages/users/UsersPage'));
const ClientsPage = lazy(() => import('../../pages/clients/ClientsPage'));
const UserDetailpage = lazy(
  () => import('../../pages/user_detail/UserDetailPage'),
);
const ClientDetailpage = lazy(
  () => import('../../pages/client_detail/ClientDetailPage'),
);
const EventsPage = lazy(() => import('../../pages/events/EventsPage'));
const EventDetailpage = lazy(
  () => import('../../pages/event_detail/EventDetailPage'),
);
const BookingsPage = lazy(() => import('../../pages/bookings/BookingsPage'));
const BookingDetailpage = lazy(
  () => import('../../pages/booking_detail/BookingDetailPage'),
);

const ShipmentsPage = lazy(() => import('../../pages/shipments/ShipmentsPage'));
const ShipmentsDetailpage = lazy(
  () => import('../../pages/shipment_detail/ShipmentDetailPage'),
);

const QuotationPage = lazy(
  () => import('../../pages/quotations/QuotationsPage'),
);
const QuotationDetailpage = lazy(
  () => import('../../pages/quotation_detail/QuotationDetailPage'),
);

const QuoteRequestsPage = lazy(
  () => import('../../pages/quoteRequests/QuoteRequestsPage'),
);
const QuoteRequestDetailpage = lazy(
  () => import('../../pages/quote_request_detail/QuoteRequestDetailPage'),
);

const ContractsPage = lazy(() => import('../../pages/contracts/ContractsPage'));
const ContractDetailpage = lazy(
  () => import('../../pages/contract_detail/ContractDetailPage'),
);

const EmissionReportsPage = lazy(
  () => import('../../pages/emission_reports/EmissionReportsPage'),
);
const EmissionReportDetailPage = lazy(
  () => import('../../pages/emission_reports_detail/EmissionReportsDetailPage'),
);

const ReleaseNotesPage = lazy(
  () => import('../../pages/releaseNotes/ReleaseNotesPage'),
);
const ReleaseNotesDetailPage = lazy(
  () => import('../../pages/release_notes_detail/ReleaseNotesDetailPage'),
);

const MaintenancePage = lazy(
  () => import('../../pages/maintenance/MaintenancePage'),
);

const FeaturesPage = lazy(() => import('../../pages/features/FeaturesPage'));

const TermsAndConditionsPage = lazy(
  () => import('../../pages/terms_and_conditions/TermsAndConditionsPage'),
);

const TermsAndConditionsDetailPage = lazy(
  () =>
    import(
      '../../pages/terms_and_conditions_detail/TermsAndConditionsDetailPage'
    ),
);

const TermsAndConditionsCreatePage = lazy(
  () =>
    import(
      '../../pages/terms_and_conditions_create/TermsAndConditionsCreatePage'
    ),
);

const ClientApiPage = lazy(
  () => import('../../pages/clients_api/clientsApiPage'),
);

const ClientApiDetailPage = lazy(
  () => import('../../pages/clients_api_detail/clientApiDetail'),
);

// const ClientApiPage = lazy(
//   () => import('../../pages/clients_api/clientsApiPage'),
// );

const PowerBIConfigPage = lazy(
  () => import('../../pages/powerbi_config/PowerBIConfigPage'),
);
const PowerBIConfigDetailPage = lazy(
  () => import('../../pages/powerbi_config_detail/PowerBIConfigDetailPage'),
);

const ApiIntegrationsPage = lazy(
  () => import('../../pages/api_integrations/ApiIntegrationsPage'),
);

const ApiIntegrationDetailPage = lazy(
  () => import('../../pages/api_integration_detail/ApiIntegrationDetailPage'),
);

const RatingsPage = lazy(() => import('../../pages/ratings/ratingsPage'));
// ----------------------------------------------------------------------

const Layout = () => {
  return (
    <AuthenticatedTemplate>
      <DashboardLayout>
        <Suspense fallback={<LoadingScreen />}>
          <MaintenanceModeAlert />
          <Outlet />
        </Suspense>
      </DashboardLayout>
    </AuthenticatedTemplate>
  );
};

export const dashboardRoutes = [
  {
    path: 'dashboard',
    element: <Layout />,
    children: [
      { element: <DashboardPage />, index: true },
      {
        path: 'users',
        children: [
          { element: <UsersPage />, index: true },
          { path: ':id', element: <UserDetailpage /> },
        ],
      },
      {
        path: 'clients',
        children: [
          { element: <ClientsPage />, index: true },
          { path: ':id', element: <ClientDetailpage /> },
        ],
      },
      {
        path: 'events',
        children: [
          { element: <EventsPage />, index: true },
          { path: ':id', element: <EventDetailpage /> },
        ],
      },
      {
        path: 'bookings',
        children: [
          { element: <BookingsPage />, index: true },
          { path: ':id/:client_id', element: <BookingDetailpage /> },
        ],
      },
      {
        path: 'shipments',
        children: [
          { element: <ShipmentsPage />, index: true },
          { path: ':id/:client_id', element: <ShipmentsDetailpage /> },
        ],
      },
      {
        path: 'quotations',
        children: [
          { element: <QuotationPage />, index: true },
          { path: ':id/:client_id', element: <QuotationDetailpage /> },
        ],
      },
      {
        path: 'quoteRequests',
        children: [
          { element: <QuoteRequestsPage />, index: true },
          { path: ':id/:client_id', element: <QuoteRequestDetailpage /> },
        ],
      },
      {
        path: 'contracts',
        children: [
          { element: <ContractsPage />, index: true },
          { path: ':id/:client_id', element: <ContractDetailpage /> },
        ],
      },
      {
        path: 'emission-reports',
        children: [
          { element: <EmissionReportsPage />, index: true },
          { path: ':id/:client_id', element: <EmissionReportDetailPage /> },
        ],
      },
      {
        path: 'release-notes',
        children: [
          { element: <ReleaseNotesPage />, index: true },
          { path: ':id', element: <ReleaseNotesDetailPage /> },
          { path: 'create', element: <ReleaseNotesDetailPage /> },
        ],
      },
      {
        path: 'maintenance',
        children: [{ element: <MaintenancePage />, index: true }],
      },
      {
        path: 'features',
        children: [{ element: <FeaturesPage />, index: true }],
      },
      { path: 'ratings', element: <RatingsPage />, index: true },
      {
        path: 'terms-and-conditions',
        children: [
          { element: <TermsAndConditionsPage />, index: true },
          { path: ':id', element: <TermsAndConditionsDetailPage /> },
          {
            path: 'new',
            element: <TermsAndConditionsCreatePage />,
          },
        ],
      },
      {
        path: 'client-api',
        children: [
          { element: <ClientApiPage />, index: true },
          { path: ':id', element: <ClientApiDetailPage /> },
        ],
      },
      {
        path: 'bi-reports',
        children: [
          { element: <PowerBIConfigPage />, index: true },
          { path: ':id', element: <PowerBIConfigDetailPage /> },
        ],
      },
      {
        path: 'api-integrations',
        children: [
          { element: <ApiIntegrationsPage />, index: true },
          { path: ':id', element: <ApiIntegrationDetailPage/>}
        ],
      },
    ],
  },
];
